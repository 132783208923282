<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                title="Sales Allocation"
            />
        </div>
        <div class="mt-2 px-2">
          <div class="row">
          <div class="col-12 col-sm-6 col-md-3 col-lg-3  mb-1">
            <div class="mb-1">
              <label for="colFormLabel" class="col-form-label">Staff Id</label>
              <input
                  class="form-control invoice-edit-input  "
                  tabindex="1"
                  type="number"
                  placeholder="Enter Staff Id"
                  v-model="staffId"
              >
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-3  mb-1">
            <div class="mb-1">
              <label for="colFormLabel" class="col-form-label">Generation Level</label>
              <input
                  class="form-control invoice-edit-input  "
                  tabindex="1"
                  type="number"
                  placeholder="Enter Generation Level"
                  v-model="generationLevel"
              >
            </div>
          </div>
            <div class="col-12 col-sm-6 col-md-3 col-lg-3  mb-1">
              <div class="mb-1">
                <label for="colFormLabel" class="col-form-label">Month</label>
                <vField
                    v-model="month"
                    name="month"
                    id="month"
                    type="month"
                    class="form-control"
                />
              </div>
            </div>
          <div class="col-12 col-sm-6 col-md-1 col-lg-1 mb-1 mt-2">
            <button
                style="min-width: 64px;"
                @click="onClickToGo"
                class="btn btn-primary btn-block waves-effect waves-float waves-light"
            > Go
            </button>
          </div>
        </div>
        </div>
      <div class="col-12" v-if="false">
        <div class="d-flex justify-content-end px-2">
          <button
              @click="onClickToPrint"
              class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect mx-1"
          >
            Print
          </button>
        </div>
      </div>

        <div class="col-12 px-2 mb-4">
          <div class="card p-2" style="min-height: 200px">
            <div class="d-flex justify-content-end px-2">
              <table class="head">
                <tr>
                  <td>Total Sales :</td>
                  <td>
                    <strong> {{ saleOrderAmount.sale_amount ?? ''}}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Allocated Amount :</td>
                  <td>
                    <strong>{{ saleOrderAmount.total_sale_allocation ?? '' }} </strong>
                  </td>
                </tr>
                <tr>
                  <td>Remaining Balance :</td>
                  <td>
                    <strong>{{ saleOrderAmount.remaining_balance ?? ''}} </strong>
                  </td>
                </tr>
              </table>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th class="text-center">Staff ID</th>
                  <th class="text-center">Staff Name</th>
                  <th class="text-center">Parent Name & ID</th>
                  <th class="text-center">Generation</th>
                  <th class="text-center">Already Allocation</th>
                  <th class="text-center">Allocation Amount</th>
                  <th class="text-center">Action</th>
                </tr>
                </thead>
                <tbody ref="tableRef">
                <tr v-for="(item, i) in tableItems" :key="i" @click="openEditLine(i)">
                  <td class="text-center">{{ item.serial_no }}</td>
                  <td class="text-center">{{ item.name }}</td>
                  <td class="text-center">{{ item.parent_name }} {{ item.parent_serial_no ? '(' + item.parent_serial_no + ')' : '' }}</td>
                  <td class="text-center">{{ item.level }}</td>
                  <td class="text-center">
                    <span v-if="!item.is_edit">
                      {{ item.allocation_amount }}
                    </span>
                    <input
                        v-if="item.allocation_amount && item.is_edit"
                        v-model.trim="item.allocation_amount"
                        type="number"
                        class="form-control text-center"
                        placeholder="Allocation Amount"
                        :min="0"
                    >
                  </td>
                  <td class="text-center">
                   <span v-if="!item.is_edit">
                      {{ item.amount }}
                    </span>
                    <input
                        v-if="item.allocation_amount == null && item.is_edit"
                        v-model.trim="item.amount"
                        type="number"
                        class="form-control text-center"
                        placeholder="Allocation Amount"
                        :min="0"
                    >
                  </td>
                  <td class="text-center">
                    <button v-if="item.allocation_amount == null && item.is_edit" :disabled="saveNewButtonLoader"  @click="onSaveItemHandler(item)" class="btn btn-primary btn-sm">Save</button>
                    <button v-if="item.allocation_amount  && item.is_edit" :disabled="saveNewButtonLoader"  @click="onUpdateItemHandler(item)" class="btn btn-primary btn-sm">Update</button>
                  </td>
                </tr>
                </tbody>
              </table>
              <p v-if="tableItems.length < 0" class="text-center mt-5">No data</p>
            </div>
          </div>
        </div>

      <AddStaffRegisterModal
          v-if="store.state.isModalOpenTwo"
          :employees="employees"
      />

        <Loader v-if="loading"/>

    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import { inject, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import handleSdm from "@/services/modules/sdm/sdm";
import { computed } from 'vue';
import handleCompany from "@/services/modules/company";
import staffRegisterPrinter from '@/services/utils/pdf/staffRegisterPrint';
import AddStaffRegisterModal from '@/components/molecule/company/sdm/AddStaffRegisterModal'
import handleContact from '@/services/modules/contact'
import useDetectOutsideClick from '@/services/useDetectOutsideClick';

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const tableItems = ref([]);
const staffRegisterData = ref([]);
const staffId = ref(null);
const generationLevel = ref(null);
const month = ref(null);
const saleOrderAmount = ref({});
const contactProfileData = ref({});
const { fetchCompanyInfo } = handleCompany();
let companyInfo = reactive({})
const { exportToPDF } = staffRegisterPrinter();
const employees = reactive([]);
const { fetchContactProfiles } = handleContact()
let saveNewButtonLoader = ref(false)
let saleAllocationData = {}
const tableRef = ref(null)
const token = computed(() => localStorage.getItem('token'));

const {
  fetchSaleAllocations,
  storeSaleAllocations
} = handleSdm()

const onClickToGo = () => {
  getAllocationData();
}

useDetectOutsideClick(tableRef, () => {
  openEditLine();
})

const onSaveItemHandler = (item) => {
  saleAllocationData.company_id  = route.params.companyId;
  saleAllocationData.contact_profile_id  = item.contact_profile_id;
  saleAllocationData.sales_person_id  = contactProfileData.value.id;
  saleAllocationData.amount  = item.amount;
  saleAllocationData.status = "active";
  saleAllocationData.date = month.value;
  saleAllocationData.is_create = true;
  saveAllocation(saleAllocationData)
}

const onUpdateItemHandler = (item) => {
  saleAllocationData.company_id  = route.params.companyId;
  saleAllocationData.contact_profile_id  = item.contact_profile_id;
  saleAllocationData.sales_person_id  = contactProfileData.value.id;
  saleAllocationData.amount  = item.allocation_amount;
  saleAllocationData.status = "active";
  saleAllocationData.date = month.value;
  saleAllocationData.is_create = false;
  saveAllocation(saleAllocationData)
}

const saveAllocation = (saleAllocationData) => {
  saveNewButtonLoader.value = true;
  storeSaleAllocations(saleAllocationData, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
        getAllocationData()
        showSuccess(res.message)
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        saveNewButtonLoader.value = false;
     })
}

async function onClickToPrint() {
  let query = getQuery();

  await fetchSaleAllocations(query).then((res) => {
    if (res.status) {
      staffRegisterData.value = res.data
    }
  }).then(() => {
    exportToPDF(companyInfo,staffRegisterData.value)
  }).catch((err) => {
    console.log("Something went wrong");
  });
}

const openEditLine = (selectedIndex = -1) => {
  for (let i = 0; i < tableItems.value.length; i++) {

    if (selectedIndex === i) {
      tableItems.value[i].is_edit = true;
      continue;
    }
    tableItems.value[i].is_edit = false;
  }
}

const getAllocationData = async () => {
    const query = getQuery();
    loading.value = true
    tableItems.value = []
    saleOrderAmount.value = {}
    contactProfileData.value = {}

    await fetchSaleAllocations(query).then(res => {
        if (!res.status){
            showError(res.message)
            return
        }

        tableItems.value = res.data.data;
        saleOrderAmount.value = res.data.sale_order;
        contactProfileData.value = res.data.contact_profile;
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const setCompanyInfo = async () => {
  await fetchCompanyInfo(route.params.companyId).then((res) => {
    companyInfo = res.data;
  }).catch((error) => {
    console.log(error);
  });
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (staffId.value) query += '&staff_id=' + staffId.value;
    if (generationLevel.value) query += '&generation=' + generationLevel.value;
    if (month.value) query += '&month=' + month.value;
    return query;
}

onMounted(() => {
  const companyQuery = `?company_id=${route.params.companyId}`
  setCompanyInfo()
    Promise.all([
    fetchContactProfiles(companyQuery + `&type=employee`).then((res) => {
      if(res.status) employees.push(...res.data)
    })
  ])
});

</script>

<style scoped>
.head td {
  text-align: right;
}
</style>